.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  font-family: lucida grande, Helvetica, Arial, sans-serif;
  font-style: normal;
  padding-top: 40px;
}

label {
  width: 350px;
}

.b2-upload-files {
  height: 350px !important;
  width: 400px !important;
}

.box_body {
  line-height: 5em;
  text-align: center;
  border-radius: 7px;
  color: #0f3c4b;
  background-color: #e5edf1;
  outline: 1px dashed gray;
  outline-offset: -6px;
  box-shadow: 0 4px 10px 4px rgb(19 35 47 / 30%);
  padding: 25px 0 0;
  width: 100%;
  height: 100%;
}

.box_icon {
  width: 100%;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  fill: #92b0b3;
}

.disabled {
  opacity: 0.5;
}

.file_table {
  text-align: left;
  margin-top: 20px;
  padding: 10px;
  width: 400px !important;
  border: 1px dashed #0f3c4b;
  background-color: #eef3f3;
  position: relative;
}

a {
  white-space: normal;
  text-overflow: unset;
  padding: 10px;
  font-size: 14px;
}

.box_text_description {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  /* padding-bottom: 20px; */
  line-height: 28px;
}

.file_table_item {
  padding: 5px 0;
  margin: 5px 0;
  display: flex;
  justify-content: space-around;
}

.file_table_item>div:first-child {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.remove_item {
  color: red;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.body_btn_upload label {
  background-color: #3498db;
  color: #fff;
  margin: 10px;
  padding: 6px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-radius: 0.2em;
  width: 100%;
  cursor: pointer;
}

.box_button {
  cursor: pointer;
  margin-top: 40px;
  box-sizing: border-box;
  border: 0;
  outline: none;
  padding: 10px;
  font-size: 1rem;
  width: 9rem;
  text-transform: uppercase;
  letter-spacing: .1em;
  background-color: #3498db;
  color: #fff;
  transition: all .5s ease;
  -webkit-appearance: none;
  box-shadow: 0 2px 5px 2px rgb(19 35 47 / 30%);
  font-weight: 700;
  display: inline-block;
  /* width: 126px; */
  height: 39px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.box_button img {
  height: 100%;
  width: auto;
  margin-left: 0.25rem;
}

.loading-svg {
  color: white;
  animation: spin 1s linear infinite;
  height: 1.5rem;
  /* margin-left: -0.25rem; */
  margin-right: 0.5rem;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-75 {
  opacity: 0.75;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}